<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/contentConfig/infoList"
            >资讯管理 /
          </router-link>
          <span class="crumbs_item crumbs_last" v-if="$route.query.operation == 1">{{
            type == 1? "新建首页资讯" : "新建教资资讯"
          }}</span>
          <span class="crumbs_item crumbs_last" v-if="$route.query.operation == 2">{{
            type == 1? "编辑首页资讯" : "编辑教资资讯"
          }}</span>
        </span>
      </div>
    </div>
    <div class="all_content_box">
      <a-form ref="ruleForm" :form="form" @submit="handleSubmit">
        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name all_required">标题：</span>
            <div class="right_Div">
              <a-input
                autocomplete="off"
                class="all_input"
                placeholder="请输入标题"
                v-decorator="[
                  'title',
                  { rules: [{ required: true, message: '请输入标题' }] },
                ]"
              />
            </div>
          </div>
        </a-form-item>

        <a-row>
          <a-col span="7" offset="2">
            <a-form-item>
              <div class="L_R_inner" style="display: inline-block; margin: 0">
                <span class="all_left_name">资讯封面图（移动端） </span>
                <div class="right_Div required">
                  <span class="ant-upload-picture-card-wrapper" v-if="appImg">
                    <div
                      class="
                        ant-upload
                        ant-upload-select
                        ant-upload-select-picture-card
                      "
                    >
                      <span role="button" tabindex="0" class="ant-upload">
                        <div class="pictures">
                          <img
                            style="max-height: 100px; max-width: 100px"
                            :src="appImg"
                            alt="avatar"
                          />
                          <div class="icon">
                            <a
                              :href="appImg"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <a-icon type="eye" class="i" />
                            </a>
                            <a-icon
                              @click="appImg = ''"
                              type="delete"
                              class="i"
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </span>
                  <a-upload
                    v-else
                    name="app"
                    list-type="picture-card"
                    :showUploadList="false"
                    accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
                    :beforeUpload="beforeUpload"
                    :customRequest="customRequest"
                  >
                    <!-- <img
                      v-if="appImg"
                      :src="appImg"
                      style="max-width: 180px"
                      alt="avatar"
                    /> -->
                    <div>
                      <a-icon :type="loading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">上传图片</div>
                    </div>
                  </a-upload>
                </div>
              </div>
            </a-form-item>
          </a-col>

          <a-col span="8">
            <a-form-item>
              <div class="L_R_inner" style="display: inline-block; margin: 0">
                <span class="all_left_name">资讯封面图（pc端） </span>
                <div class="right_Div">
                  <span class="ant-upload-picture-card-wrapper" v-if="pcImg">
                    <div
                      class="
                        ant-upload
                        ant-upload-select
                        ant-upload-select-picture-card
                      "
                    >
                      <span role="button" tabindex="0" class="ant-upload">
                        <div class="pictures">
                          <img
                            style="max-height: 100px; max-width: 100px"
                            :src="pcImg"
                            alt="avatar"
                          />
                          <div class="icon">
                            <a
                              :href="pcImg"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <a-icon type="eye" class="i" />
                            </a>
                            <a-icon
                              @click="pcImg = ''"
                              type="delete"
                              class="i"
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </span>
                  <a-upload
                    v-else
                    name="pc"
                    list-type="picture-card"
                    :showUploadList="false"
                    accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
                    :beforeUpload="beforeUpload"
                    :customRequest="customRequest"
                  >
                    <!-- <a-icon :type="uploadLoading ? 'loading' : 'plus'" />

                    <img
                      v-if="pcImg"
                      :src="pcImg"
                      style="max-width: 180px"
                      alt="avatar"
                    /> -->
                    <div>
                      <a-icon :type="loading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">上传图片</div>
                    </div>
                  </a-upload>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>

        <!-- <a-form-item>
          <div class="L_R_inner" style="display: inline-block">
            <span class="all_left_name">偏好设置：</span>
            <span class="all_left_info">（可多选）</span>
            <a-col offset="1">
              <div class="right_Div">
                <a-checkbox-group
                  class="radioGroup"
                  style="line-height: 38px; margin-left: 10px"
                  @change="changelikeLable"
                  v-decorator="[
                    'likeList',
                    { rules: [{ required: false}] },
                  ]"
                >
                  <a-checkbox
                    v-for="(item, index) in likeList"
                    :key="index"
                    :value="item.likeId"
                    >{{ item.likeLabelName }}</a-checkbox
                  >
                </a-checkbox-group>
              </div>
            </a-col>
          </div>
        </a-form-item> -->

        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name all_required">摘要：</span>
            <div class="right_Div">
              <a-input
                class="all_input"
                autocomplete="off"
                placeholder="请输入摘要"
                v-decorator="[
                  'summary',
                  { rules: [{ required: true, message: '请输入摘要' },
                            { max: 100, message: '摘要长度不能超过100个字符', trigger: 'change' }] },
                ]"
              />
            </div>
          </div>
        </a-form-item>

        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name all_required">app详情：</span>
            <UE
              class="editor"
              editorId="introduceEditor1"
              :initContent="ueInitText"
              @input="getContent"
            />
          </div>
        </a-form-item>

        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name all_required">web详情：</span>
            <UE
              class="editor"
              editorId="introduceEditor2"
              :initContent="uepcInitText"
              @input="getContent1"
            />
          </div>
        </a-form-item>

        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name">作者：</span>
            <div class="right_Div">
              <a-input
                class="all_input"
                autocomplete="off"
                placeholder="请输入作者"
                v-decorator="['author', { rules: [{ required: false }] }]"
              />
            </div>
          </div>
        </a-form-item>

        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name all_required">来源：</span>
            <div class="right_Div">
              <a-input
                class="all_input"
                autocomplete="off"
                placeholder="请输入来源"
                :maxLength="10"
                v-decorator="[
                  'source',
                  { rules: [{ required: true, message: '请输入来源' }] },
                ]"
              />
            </div>
          </div>
        </a-form-item>

        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name all_required">浏览量：</span>
            <div class="right_Div">
              <a-input-number
                :min=0
                class="all_input"
                autocomplete="off"
                placeholder="请输入浏览量"
                v-decorator="[
                  'views',
                  { rules: [{ required: true, message: '请输入浏览量' }] },
                ]"
              />
            </div>
          </div>
        </a-form-item>

        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name all_required">所属分类：</span>
            <div class="right_Div">
              <a-select
                class="search-input"
                placeholder="请选择分类"
                @change="changeNewType"
                v-decorator="[
                  'newsTypeId',
                  { rules: [{ required: true, message: '请选择分类' }] },
                ]"
              >
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option
                  v-for="(item, index) in newTypeList"
                  :key="index"
                  :value="item.newsTypeId"
                  >{{ item.newsTypeName }}</a-select-option
                >
              </a-select>
            </div>
          </div>
        </a-form-item>

        <div class="button">
          <a-button type="primary" class="btn" html-type="submit" :loading="submitLoading"
            >保存</a-button
          >
          <a-button class="all_boder_btn btn" @click="$router.go(-1)"
            >取消</a-button
          >
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import UE from "@/components/UE.vue";
export default {
  // 可用组件的哈希表
  components: { UE }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      type: '',
      loading: false,
      submitLoading: false, //提交中
      state: 1, //1新增 2编辑
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      tableData: {}, //详情
      newId: "", //资讯id
      userType: "", //资讯分类
      // likeList: [], //偏好设置
      newTypeList: [], //资讯分类

      appImg: "", //移动端主图
      pcImg: "", //pc主图
      appDetails: "", //移动端详情
      pcDetails: "", //pc详情
      ueInitText: "", //移动端详情-回显用
      uepcInitText: "", //pc端详情-回显用
    };
  }, // 事件处理器
  methods: {
    // 偏好设置
    async getLikeList() {
      await this.$ajax({
        url: "/hxclass-management/like/",
        method: "get",
      }).then((res) => {
        this.likeList = res.data;
      });
    },
    // 资讯分类
    async getNewType() {
      await this.$ajax({
        url: "/hxclass-management/news/type/" + this.type,
        method: "get",
      }).then((res) => {
        this.newTypeList = res.data;
      });
    },
    // 资讯详情
    async getData() {
      await this.$ajax({
        url: "/hxclass-management/news/details/" + this.newId,
        method: "get",
      }).then((res) => {
        let tableData = res.data;

        // 偏好设置
        // tableData.likeList = JSON.parse(tableData.likeList);
        this.$nextTick(() => {
          this.form.setFieldsValue({
            title: tableData.title, //标题
            summary: tableData.summary, //摘要
            author: tableData.author, //作者
            source: tableData.source, //来源
            views: tableData.views, //浏览量
            newsTypeId: tableData.newsTypeId, //分类
          });
        });
        this.ueInitText = tableData.appDetails; //app详情
        this.uepcInitText = tableData.pcDetails; //pc详情
        this.appImg = res.data.appImg; //app图片
        this.pcImg = res.data.pcImg; //pc图片
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 偏好设置转为字符串
          // values.likeList = values.likeList ? values.likeList.join(",") : ''
          values.appImg = this.appImg;
          values.pcImg = this.pcImg;
          values.appDetails = this.appDetails;
          values.pcDetails = this.pcDetails;
          this.onSubmit(values);
        } else {
          this.$message.warning("请检查必填项是否填写完整！");
        }
      });
    },
    // 提交
    onSubmit(value) {
      // 提交中
      if (!!this.submitLoading) {
        return
      }
      this.submitLoading = true //提交中
      // 提交
      if (!this.appDetails) {
        this.$message.warning("请填写app详情");
        this.submitLoading = false //提交中
        return;
      }
      if (!this.pcDetails) {
        this.$message.warning("请填写web详情");
        this.submitLoading = false //提交中
        return;
      }

      let params = {};
      let method = "";
      // 新增
      if (this.state == 1) {
        params = { ...value };
        method = "POST";
      } else {
        // 编辑
        params = {
          ...value,
          newsId: this.newId,
        };
        method = "PUT";
      }
      this.$ajax({
        url: "/hxclass-management/news/",
        method,
        params,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(this.state == 1 ? "提交成功" : "编辑成功");
          this.$router.go(-1);
        }
        this.submitLoading = false //提交完毕
      });
    },
    // // 偏好设置
    // changelikeLable(value) {
    // console.log(value);
    // },
    // 上传前钩子
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
    },

    // 文件上传
    customRequest(fileData) {
      const fileName = fileData.filename // 名称前缀
      let picture = fileName + 'Img'

      this.loading = true;

      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this[picture] = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    
    // 移动端详情
    getContent(e) {
      this.$set(this, "appDetails", e);
    },
    // pc详情
    getContent1(e) {
      this.$set(this, "pcDetails", e);
    },
    // 资讯分类选择
    changeNewType(e) {
      // console.log(e);
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    // 偏好设置
    // this.getLikeList();

    let query = this.$route.query;

    this.type = query.type;
    // 编辑
    if (query.operation == 2) {
      this.newId = query.id;
      this.state = 2;
      this.getData();
    }

    // 资讯分类
    this.getNewType();

  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/.ant-form-item {
  margin: 24px;
}
/deep/.all_content_box .all_input {
  height: 37px;
}
/deep/.ant-input-number-input {
  height: 37px;
  padding: 0;
}
/deep/.ant-form-explain {
  margin-left: 160px;
}
/deep/.ant-checkbox-wrapper {
  margin-left: 0;
  margin-right: 40px;
}
.ant-upload-picture-card-wrapper {
  width: auto;
}
.all_main_wrap {
  a {
    color: #333333;
  }

  .title-blod {
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
  }

  .all_content_box {
    .L_R_inner {
      // margin: 24px;
      .greytext {
        font-size: 14px;
        color: #888;
      }

      .all_left_name {
        width: 140px;
        text-align: right;
      }
      .all_left_info {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      .vue-ueditor-wrap[data-v-1e1388a0] {
        margin-left: 20px;
        width: 600px;
        margin-top: 10px;
      }

      .right_Div {
        .inneer_img {
          width: 100px;
          margin-right: 8px;
          margin-bottom: 8px;
        }
        .ant-select,
        .all_input {
          width: 337px;
        }
        /deep/.ant-input {
          height: 37px;
        }
      }
      .editor{
        min-width: 1200px;
      }
    }
  }

  .button {
    .btn {
      margin-left: 20px;
    }
  }
}

.ant-upload.ant-upload-select-picture-card {
  display: table;
  float: left;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
.pictures .icon {
  transition: all 0.3s;
  opacity: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pictures:hover {
  .icon {
    opacity: 1;
  }
}
.pictures {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
</style>
